import { SimpleObject } from '../entities/simple-object';

interface Parameters {
  [key: string]: SimpleObject;
}

const backendBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.allergomedica.lt';
const appWebsiteUrl = process.env.REACT_APP_WEBSITE_URL || 'https://app.allergomedica.lt';

const backendBaseUrlPL = process.env.REACT_APP_API_BASE_URL_PL || 'https://api.allergomedica.pl';
const appWebsiteUrlPL = process.env.REACT_APP_WEBSITE_URL_PL || 'https://allergomedica.pl';

export const parametersValues: Parameters = {
  allergomedica: {
    lt: {
      isDevelopment: process.env.NODE_ENV === 'development',
      api: {
        baseUrl: backendBaseUrl,
        websiteUrl: appWebsiteUrl,
      },
    },
    pl: {
      isDevelopment: process.env.NODE_ENV === 'development',
      api: {
        baseUrl: backendBaseUrlPL,
        websiteUrl: appWebsiteUrlPL,
      },
    },
  },
};

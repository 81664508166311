import axios from 'axios';

import { parameters } from '../../localization/strings';
import { LisData } from '../../entities/lisData';

export const getLisData = async (): Promise<LisData> => {
  const url = `${parameters().api.baseUrl}/services/lis-data`;

  const result = await axios.get(url);

  return result.data;
};

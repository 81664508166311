import axios from 'axios';

import { parameters } from '../../localization/strings';
import { BloodSample } from '../../entities/blodSample';

export const createBloodSample = async (data: object): Promise<BloodSample> => {
  try {
    const url = `${parameters().api.baseUrl}/blood-samples/create`;

    const res = await axios.post(url, data);

    return res.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.error) {
        throw new Error(e.response?.data.error);
      }
    }

    throw e;
  }
};

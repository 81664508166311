import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import Root from './root/root';
import { SiteContextProvider } from '../context/site';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SiteContextProvider>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </SiteContextProvider>
    </QueryClientProvider>
  );
}

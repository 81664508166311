import React from 'react';
import { Disclosure } from '@headlessui/react';

import IMAGES from '../../assets/images';
import { strings } from '../../localization/strings';

export default function LayoutHeadingComponent() {
  return (
    <Disclosure as="nav" className="shadow z-50 relative bg-white">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex w-full justify-between items-center">
            <div className="flex-shrink-0 flex items-center space-x-10">
              <a className="py-2" href="https://partners.allergomedica.lt">
                <span className="sr-only">AllergoMedica</span>
                <img className="h-8 w-auto sm:h-8" src={IMAGES.LOGOTYPES.PRIMARY} alt="AllergoMedica" />
              </a>
              <div className="text-sm hidden sm:block">{strings().navigation.bloodSampleRegistration}</div>
            </div>

            <div className="text-sm text-right sm:text-left">
              {strings().navigation.haveQuestions}
              <a className="no-underline text-gray-600 inline-flex ml-2" href={`tel:${strings().navigation.phone}`}>
                {strings().navigation.phone}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Disclosure>
  );
}

import React from 'react';
import { useRoutes } from 'react-router-dom';

import LayoutHeadingComponent from '../../components/layout/heading';
import SearchOrderPageComponent from '../SearchOrder/search';

export default function Root() {
  const element = useRoutes([
    {
      path: '/',
      element: <SearchOrderPageComponent />,
    },
  ]);

  return (
    <div>
      <LayoutHeadingComponent />
      {element}
    </div>
  );
}

import React from 'react';
import { ExclamationIcon, XCircleIcon } from '@heroicons/react/solid';

interface ComponentProps {
  title?: string;
  text?: string;
  type?: string;
  marginTop?: string;
  marginBottom?: string;
}

function AlertPrimary({ title, text, type, marginTop, marginBottom }: ComponentProps) {
  return (
    <div
      className={`rounded-2xl p-4 md:p-5 ${type === 'failed' && 'bg-red-50'} ${type === 'danger' && 'bg-red-50'} ${
        type === 'success' && 'bg-success-300'
      }  ${type === 'warning' && 'bg-yellow-50'}  ${marginTop} ${marginBottom}`}
    >
      <div className="flex-row flex items-center">
        <div className="w-8">
          {type === 'success' && (
            <div className="mx-auto flex items-center justify-center h-8 w-8 rounded-full bg-green-600">
              <svg
                className="h-6 w-6 text-white"
                x-description="Heroicon name: outline/check"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
            </div>
          )}
          {type === 'failed' && (
            <XCircleIcon className="h-8 w-8 mb-2 mx-auto md:mb-0 md:h-10 md:w-10 text-orange-400" aria-hidden="true" />
          )}
          {type !== 'failed' && type !== 'success' && (
            <ExclamationIcon
              className={`h-8 w-8 mb-2 mx-auto md:mb-0 md:h-9 md:w-9 text-gray-700 ${
                type === 'danger' && 'text-red-500'
              }
              ${type === 'warning' && 'text-yellow-400'}
              
              `}
              aria-hidden="true"
            />
          )}
        </div>

        <div className={`pl-4  ${type === 'warning' && 'text-yellow-800'}`}>
          {title && <h3 className="font-bold font-base">{title}</h3>}
          {text && <div className="mt-1 text-sm">{text}</div>}
        </div>
      </div>
    </div>
  );
}
AlertPrimary.defaultProps = {
  type: 'primary',
  title: '',
  text: '',
  marginTop: 'mt-0',
  marginBottom: 'mb-0',
};

export default AlertPrimary;

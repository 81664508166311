import React, { useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { format, subMinutes } from 'date-fns';

import { BloodSample } from '../../entities/blodSample';
import CommonButtonDefault from '../common/buttons/default';
import StyledError from '../common/form/styled-error';
import StyledLabel from '../common/form/styled-label';
import StyledInput from '../common/form/styled-input';
import { strings } from '../../localization/strings';
import { Location } from '../../entities/location';
import AlertPrimary from '../common/alerts/alert-primary';
import { LisData } from '../../entities/lisData';
import { Option } from '../../entities/option';
import CommonAnimatedLoader from '../common/animated/loader';

interface ComponentProps {
  locations: Location[];
  lisData: LisData;
  orderId: number;
  birthDate: string;
  step: number;
  onCompleteBloodForm: (completed: boolean) => void;
  onCompleteBloodRegistration: (data: BloodSample) => void;
  registerFormError?: string;
}

function RegisterBloodFormComponent({
  locations,
  onCompleteBloodForm,
  onCompleteBloodRegistration,
  orderId,
  birthDate,
  lisData,
  step,
  registerFormError,
}: ComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [currentLocation, setCurrentLocation] = useState<Location | null>(null);
  const [isFormFilled, setIsFormFilled] = useState<boolean>(false);

  const anatomicalAreaOptions: Option[] = [
    { value: 0, label: strings().anatomicalArea.anatomicalAreaOptions.fingerPhalanx },
    { value: 1, label: strings().anatomicalArea.anatomicalAreaOptions.internalFlexionOfElbow },
    { value: 2, label: strings().other },
  ];

  const sampleTypesOptions: Option[] = [
    {
      value: 1,
      // label: "Veninis"
      label: strings().sampleType.sampleTypeOptions.venousBlood,
    },
    {
      value: 2,
      // label: "Kapiliarinis"
      label: strings().sampleType.sampleTypeOptions.capillaryBlood,
    },
    // {
    //   value: 3,
    //   // label: "Nosies landų tepinėlis"
    //   label: strings().sampleType.sampleTypeOptions.nasalSwab,
    // },
    // {
    //   value: 4,
    //   // label: "Blood serum"
    //   label: strings().sampleType.sampleTypeOptions.bloodSerum,
    // },
    // {
    //   value: 5,
    //   // label: 'Sauso kraujo ėminys',
    //   label: strings().sampleType.sampleTypeOptions.dryBloodSample,
    // },
  ];

  const amountOfSamples: Option[] = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
  ];

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    control,
    getValues,
    trigger,
  } = useForm<BloodSample>();
  const onSubmit: SubmitHandler<BloodSample> = async (data) => {
    /** perduoti orderId, User Info, for research * */
    try {
      const formData = data;
      const createdDate = format(new Date(data.sampledDate), 'yyyy-MM-dd');

      if (createdDate) {
        formData.sampledDate = createdDate;
      }

      if (orderId) {
        formData.orderId = orderId;
      }

      if (birthDate) {
        formData.birthDate = birthDate;
      }

      await onCompleteBloodRegistration(formData);
    } catch (e) {
      if (e instanceof Error) {
        setError('common', { message: e.message });
      }
    }
  };

  const onFillForm = async () => {
    const output = await trigger();

    if (output) {
      setIsFormFilled(true);
      clearErrors('common');
      onCompleteBloodForm(true);
    }
  };

  const onReturnToEditing = () => {
    onCompleteBloodForm(false);
    setIsFormFilled(false);
  };

  const findLocationName = (id: number) => {
    const location = locations.find((d) => d.id === id);

    return [location?.title];
  };

  const mapSelectValue = (values: Option[], selectedId: string | number) => {
    const selected = values.find((v) => v.value === selectedId);

    if (selected) {
      return selected.label;
    }

    return '';
  };

  return isLoading ? (
    <CommonAnimatedLoader />
  ) : (
    <div>
      {errors.common && <AlertPrimary title={errors?.common?.message} type="danger" marginBottom="mb-4" />}
      {registerFormError && <AlertPrimary title={registerFormError} type="danger" marginBottom="mb-4" />}
      <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
        {!isFormFilled || step === 2 ? (
          <div className="space-y-5">
            <div>
              <StyledLabel htmlFor="collectionLocationId">{strings().bloodSampleRegistration.clinic}</StyledLabel>
              {locations && (
                <div>
                  <Controller
                    control={control}
                    name="collectionLocationId"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        classNamePrefix="react-select"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: errors.collectionLocationId ? '#f56565' : 'rgba(209, 213, 219)',
                          }),
                        }}
                        className="block w-full rounded-md !border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        options={locations}
                        value={locations.find((option) => option.id === field.value)}
                        placeholder={strings().bloodRegistration.pickerPlaceholder}
                        getOptionValue={(option: Location) => `${option.id}`}
                        getOptionLabel={(option: Location) => `${option.partnerName}, ${option.title}`}
                        onChange={(e) => {
                          field.onChange(e?.id);
                        }}
                      />
                    )}
                  />
                  {errors.collectionLocationId && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
                </div>
              )}
            </div>
            <div>
              <StyledLabel htmlFor="employeeFullName">{strings().bloodSampleRegistration.employee}</StyledLabel>
              <StyledInput
                fullWidth
                type="text"
                className={` ${errors.employeeFullName && `!border-red-500`} `}
                {...register('employeeFullName', { required: true })}
              />
              {errors.employeeFullName && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
            </div>
            <div>
              <StyledLabel htmlFor="sampledDate">{strings().bloodSampleRegistration.sampleDate}</StyledLabel>
              <div>
                <Controller
                  control={control}
                  name="sampledDate"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      // placeholderText={strings().form.placeholderBirthDate}
                      selected={field.value ? new Date(field.value) : undefined}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      className={`block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                        errors.sampledDate && `!border-red-500`
                      } `}
                    />
                  )}
                />
                {errors.sampledDate && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
              </div>
            </div>
            <div>
              <StyledLabel htmlFor="sampledTime">{strings().bloodSampleRegistration.sampleTime}</StyledLabel>
              <StyledInput
                fullWidth
                type="text"
                defaultValue={format(subMinutes(new Date(), 10), 'HH:mm')}
                className={` ${errors.sampledTime && `!border-red-500`} `}
                {...register('sampledTime', { required: true })}
              />
              {errors.sampledTime && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
            </div>

            <div>
              <StyledLabel htmlFor="amountOfSamples">{strings().bloodSampleRegistration.amountOfSamples}</StyledLabel>
              <Controller
                control={control}
                name="quantity"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: errors.quantity ? '#f56565' : 'rgba(209, 213, 219)',
                      }),
                    }}
                    placeholder={strings().form.select.pickerPlaceholder}
                    classNamePrefix="react-select"
                    options={amountOfSamples}
                    value={amountOfSamples.find((option) => option.value === field.value)}
                    className={`block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                      errors.quantity && `!border-red-500`
                    } `}
                    // placeholder={strings().form.genderPickerPlaceholder}
                    onChange={(e) => {
                      field.onChange(e?.value);
                    }}
                  />
                )}
              />
              {errors.quantity && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
            </div>
            <div>
              <StyledLabel htmlFor="sampleTypeId">{strings().sampleType.label}</StyledLabel>
              <Controller
                control={control}
                name="sampleTypeId"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    placeholder={strings().form.select.pickerPlaceholder}
                    classNamePrefix="react-select"
                    options={sampleTypesOptions}
                    value={sampleTypesOptions.find((option) => option.value === field.value)}
                    className={`block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                      errors.sampleTypeId && `!border-red-500`
                    } `}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: errors.sampleTypeId ? '#f56565' : 'rgba(209, 213, 219)',
                      }),
                    }}
                    // placeholder={strings().form.genderPickerPlaceholder}
                    onChange={(e) => {
                      field.onChange(e?.value);
                    }}
                  />
                )}
              />
              {errors.sampleTypeId && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
            </div>

            <div>
              <StyledLabel htmlFor="anatomicalAreaId">{strings().anatomicalArea.label}</StyledLabel>
              <Controller
                control={control}
                name="anatomicAreaId"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    placeholder={strings().form.select.pickerPlaceholder}
                    classNamePrefix="react-select"
                    options={anatomicalAreaOptions}
                    value={anatomicalAreaOptions.find((option) => option.value === field.value)}
                    className={`block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${
                      errors.anatomicAreaId && `!border-red-500`
                    } `}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: errors.anatomicAreaId ? '#f56565' : 'rgba(209, 213, 219)',
                      }),
                    }}
                    // placeholder={strings().form.genderPickerPlaceholder}
                    onChange={(e) => {
                      field.onChange(e?.value);
                    }}
                  />
                )}
              />
              {errors.anatomicAreaId && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
            </div>

            <div>
              <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                {strings().bloodSampleRegistration.comments}
              </label>
              <div className="mt-1">
                <textarea
                  rows={4}
                  id="comment"
                  className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                    errors.notes && `border-red-500`
                  } `}
                  defaultValue=""
                  {...register('notes')}
                />
              </div>
              {errors.notes && <StyledError>{strings().error.fieldIsRequired}</StyledError>}
            </div>
            <CommonButtonDefault onClick={onFillForm} primary className="mt-6">
              {strings().button.next}
            </CommonButtonDefault>
          </div>
        ) : (
          <div>
            <AlertPrimary
              title={strings().alert.warning.title}
              text={strings().alert.warning.text}
              type="warning"
              marginBottom="mb-4"
            />
            <div className="border-t border-gray-200">
              <dl className="sm:divide-y sm:divide-gray-200 border-b border-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-3">
                  <dt className="text-sm text-gray-500">{strings().bloodSampleRegistration.clinic}</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                    {findLocationName(getValues('collectionLocationId'))}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-3">
                  <dt className="text-sm text-gray-500">{strings().bloodSampleRegistration.employee}</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{getValues('employeeFullName')}</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-3">
                  <dt className="text-sm text-gray-500">{strings().bloodSampleRegistration.sampleDate}</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                    {format(new Date(getValues('sampledDate')), 'yyyy-MM-dd')}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-3">
                  <dt className="text-sm text-gray-500">{strings().bloodSampleRegistration.sampleTime}</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{getValues('sampledTime')}</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-3">
                  <dt className="text-sm text-gray-500">{strings().bloodSampleRegistration.amountOfSamples}</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{getValues('quantity')}</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-3">
                  <dt className="text-sm text-gray-500">{strings().bloodSampleRegistration.type}</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                    {mapSelectValue(lisData.sampleTypes, getValues('sampleTypeId'))}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-3">
                  <dt className="text-sm text-gray-500">{strings().bloodSampleRegistration.anatomicalArea}</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                    {mapSelectValue(anatomicalAreaOptions, getValues('anatomicAreaId'))}
                  </dd>
                </div>
                {getValues('notes') && (
                  <div className="py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-3">
                    <dt className="text-sm text-gray-500">{strings().bloodSampleRegistration.comments}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{getValues('notes')}</dd>
                  </div>
                )}
              </dl>
              <div className="flex space-x-4 mt-6">
                <CommonButtonDefault type="submit" primary>
                  {strings().button.confirm}
                </CommonButtonDefault>
                <CommonButtonDefault link onClick={onReturnToEditing}>
                  {strings().button.edit}
                </CommonButtonDefault>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

RegisterBloodFormComponent.defaultProps = {
  registerFormError: '',
};

export default RegisterBloodFormComponent;

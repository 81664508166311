import axios from 'axios';

import { parameters } from '../../localization/strings';
import { Location } from '../../entities/location';

export const getLocationsList = async (): Promise<Location[]> => {
  const url = `${parameters().api.baseUrl}/locations/list`;

  const result = await axios.get(url);

  return result.data;
};

import React from 'react';
import { format } from 'date-fns';

import Heading1 from '../common/headings/heading1';
import { Order } from '../../entities/order';
import { strings } from '../../localization/strings';

interface ComponentProps {
  order: Order;
}

function HeaderComponent({ order }: ComponentProps) {
  return (
    <header className="mb-3 mt-3">
      <Heading1 className="text-3xl leading-tight text-gray-900">
        <span className="font-bold">
          {order.patientName} {order.patientLastName}
        </span>{' '}
        {order.patientBirthDate && format(new Date(order.patientBirthDate), 'yyyy-MM-dd')}
      </Heading1>
      <div className="text-sm text-gray-500">
        {strings().header.orderId}: {order.id}
      </div>
    </header>
  );
}

export default HeaderComponent;
